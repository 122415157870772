/*
 * (c) 2022 CARIAD SE, All rights reserved.
 *
 * NOTICE:
 * All the information and materials contained herein, including the intellectual and technical concepts,
 * are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
 * The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
 * that are confidential and/or proprietary and trade secrets of CARIAD SE.
 * Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
 * information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
 * The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
 * contents or to manufacture, use or sell anything that it may describe in whole or in part.
 */

export const DASHBOARD = 'DASHBOARD';
export const DEPLOYMENT_TRACKING = 'DEPLOYMENT_TRACKING';
export const DEPLOYMENT_TRACKING_DASHBOARD = 'DEPLOYMENT_TRACKING_DASHBOARD';
export const DEPLOYMENT_TRACKING_DATAIMPORT = 'DEPLOYMENT_TRACKING_DATAIMPORT';
export const DEPLOYMENT_TRACKING_DATAIMPORT_SALES_DATA = 'DEPLOYMENT_TRACKING_DATAIMPORT_SALES_DATA';
export const DEPLOYMENT_TRACKING_DATAIMPORT_FLEET_CAPABILITY = 'DEPLOYMENT_TRACKING_DATAIMPORT_FLEET_CAPABILITY';
export const DEPLOYMENT_TRACKING_DATAIMPORT_TRACKING_DATA = 'DEPLOYMENT_TRACKING_DATAIMPORT_TRACKING_DATA';
export const ORU23_CAMPAIGN_REPORTING = 'ORU23_CAMPAIGN_REPORTING';
export const ORU23_CAMPAIGN_REPORTING_DASHBOARD = 'ORU23_CAMPAIGN_REPORTING_DASHBOARD';
export const ORU23_CAMPAIGN_REPORTING_DATAIMPORT = 'ORU23_CAMPAIGN_REPORTING_DATAIMPORT';
export const ERROR = 'ERROR';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const PROFILE = 'PROFILE';
export const RELEASE_NOTES = 'RELEASE_NOTES';
export const LEGAL_NOTICE = 'LEGAL_NOTICE';
export const HELP = 'HELP';
export const CAMPAIGN_MANAGEMENT = 'CAMPAIGN_MANAGEMENT';

export const CM_DEVELOPER_MODE_DEPLOYMENTS = 'CM_DEVELOPER_MODE_DEPLOYMENTS';
export const CM_DEPLOYMENTS_CREATE_SELECTION = 'CM_DEPLOYMENTS_CREATE_SELECTION';
export const CM_RELEASES = 'CM_RELEASES';
export const CM_RELEASES_CREATE = 'CM_RELEASES_CREATE';
export const CM_RELEASES_MODULES = 'CM_RELEASES_MODULES';
export const CM_RELEASES_MODULES_EDIT = 'CM_RELEASES_MODULES_EDIT';
export const CM_RELEASES_PROPERTIES_EDIT = 'CM_RELEASES_PROPERTIES_EDIT';

export const CM_BIGLOOP_CAMPAIGN_EDIT = 'CM_BIGLOOP_CAMPAIGN_EDIT';
export const CM_BIGLOOP_CAMPAIGN_EDIT_OVERVIEW = 'CM_BIGLOOP_CAMPAIGN_EDIT_OVERVIEW';
export const CM_BIGLOOP_CAMPAIGN_EDIT_PARAMETERS = 'CM_BIGLOOP_CAMPAIGN_EDIT_PARAMETERS';
export const CM_BIGLOOP_CAMPAIGN_EDIT_VEHICLES = 'CM_BIGLOOP_CAMPAIGN_EDIT_VEHICLES';
export const CM_BIGLOOP_CAMPAIGN_EDIT_RELEASE = 'CM_BIGLOOP_CAMPAIGN_EDIT_RELEASE';
export const CM_BIGLOOP_CAMPAIGN_EDIT_MANIFEST = 'CM_BIGLOOP_CAMPAIGN_EDIT_MANIFEST';
export const CM_BIGLOOP_CAMPAIGN_EDIT_ROLLOUT = 'CM_BIGLOOP_CAMPAIGN_EDIT_ROLLOUT';
export const CM_BIGLOOP_CAMPAIGN_EDIT_ANALYTICS = 'CM_BIGLOOP_CAMPAIGN_EDIT_ANALYTICS';
export const CM_BIGLOOP_SW_DELIVERY_RELEASE_EDIT = 'CM_BIGLOOP_SW_DELIVERY_RELEASE_EDIT';
export const CM_BIGLOOP_SW_DELIVERY_RELEASE_EDIT_OVERVIEW = 'CM_BIGLOOP_SW_DELIVERY_RELEASE_EDIT_OVERVIEW';
export const CM_BIGLOOP_SW_DELIVERY_RELEASE_EDIT_PROPERTIES = 'CM_BIGLOOP_SW_DELIVERY_RELEASE_EDIT_PROPERTIES';
export const CM_BIGLOOP_SW_DELIVERY_RELEASE_EDIT_APPROVALS = 'CM_BIGLOOP_SW_DELIVERY_RELEASE_EDIT_APPROVALS';

export const CM_ORU20_CAMPAIGN_EDIT = 'CM_ORU20_CAMPAIGN_EDIT';
export const CM_ORU20_CAMPAIGN_EDIT_OVERVIEW = 'CM_ORU20_CAMPAIGN_EDIT_OVERVIEW';
export const CM_ORU20_CAMPAIGN_EDIT_PARAMETERS = 'CM_ORU20_CAMPAIGN_EDIT_PARAMETERS';
export const CM_ORU20_CAMPAIGN_EDIT_VEHICLES = 'CM_ORU20_CAMPAIGN_EDIT_VEHICLES';
export const CM_ORU20_CAMPAIGN_EDIT_RELEASE = 'CM_ORU20_CAMPAIGN_EDIT_RELEASE';
export const CM_ORU20_CAMPAIGN_EDIT_MANIFEST = 'CM_ORU20_CAMPAIGN_EDIT_MANIFEST';
export const CM_ORU20_CAMPAIGN_EDIT_ROLLOUT = 'CM_ORU20_CAMPAIGN_EDIT_ROLLOUT';
export const CM_ORU20_CAMPAIGN_EDIT_ANALYTICS = 'CM_ORU20_CAMPAIGN_EDIT_ANALYTICS';
export const CM_ORU20_SW_DELIVERY_RELEASE_EDIT = 'CM_ORU20_SW_DELIVERY_RELEASE_EDIT';
export const CM_ORU20_SW_DELIVERY_RELEASE_EDIT_OVERVIEW = 'CM_ORU20_SW_DELIVERY_RELEASE_EDIT_OVERVIEW';
export const CM_ORU20_SW_DELIVERY_RELEASE_EDIT_PROPERTIES = 'CM_ORU20_SW_DELIVERY_RELEASE_EDIT_PROPERTIES';
export const CM_ORU20_SW_DELIVERY_RELEASE_EDIT_APPROVALS = 'CM_ORU20_SW_DELIVERY_RELEASE_EDIT_APPROVALS';

export const CM_DRM_SW_PROJECT_EDIT = 'CM_DRM_SW_PROJECT_EDIT';
export const CM_DRM_RELEASE_VERSION = 'CM_DRM_RELEASE_VERSION';
export const CM_DRM_RELEASE_VERSION_PROPERTIES_EDIT = 'CM_DRM_RELEASE_VERSION_PROPERTIES_EDIT';
export const CM_DRM_RELEASE_VERSION_CONFIGURATION = 'CM_DRM_RELEASE_VERSION_CONFIGURATION';
export const CM_DRM_RELEASE_VERSION_PROPERTIES_EDIT_TARGET_VEHICLES =
  'CM_DRM_RELEASE_VERSION_PROPERTIES_EDIT_TARGET_VEHICLES';
export const CM_DRM_RELEASE_DEPLOYMENTS = 'CM_DRM_RELEASE_DEPLOYMENTS';
export const CM_DRM_DEPLOYMENT = 'CM_DRM_DEPLOYMENT';
export const CM_DRM_DEPLOYMENT_OVERVIEW = 'CM_DRM_DEPLOYMENT_OVERVIEW';
export const CM_DRM_DEPLOYMENT_PARAMETER = 'CM_DRM_DEPLOYMENT_PARAMETER';
export const CM_DRM_DEPLOYMENT_TARGET_VEHICLES = 'CM_DRM_DEPLOYMENT_TARGET_VEHICLES';
export const CM_DRM_DEPLOYMENT_DEPLOY = 'CM_DRM_DEPLOYMENT_DEPLOY';

export const CM_VIN_LIST = 'CM_VIN_LIST';
export const CM_VIN_LIST_EDIT = 'CM_VIN_LIST_EDIT';
export const CM_VIN_LIST_EDIT_TARGET_VEHICLES = 'CM_VIN_LIST_EDIT_TARGET_VEHICLES';
export const CM_VIN_LIST_EDIT_PROPERTIES = 'CM_VIN_LIST_EDIT_PROPERTIES';

export const CM_CAMPAIGNS = 'CM_CAMPAIGNS';
export const CM_CAMPAIGN_CREATE_SELECTION = 'CM_CAMPAIGN_CREATE_SELECTION';
export const DATA_LOAD_FORM = 'DATA_LOAD_FORM';
export const DATA_LOAD_CAMPAIGN_EDIT = 'DATA_LOAD_CAMPAIGN_EDIT';
export const DATA_LOAD_OVERVIEW = 'DATA_LOAD_OVERVIEW';
export const DATA_LOAD_PROPERTIES = 'DATA_LOAD_PROPERTIES';
export const DATA_LOAD_BATCHES = 'DATA_LOAD_BATCHES';
export const DATA_LOAD_CAMPAIGN_BATCH_ADD = 'DATA_LOAD_CAMPAIGN_BATCH_ADD';
export const DATA_E3_1_1_DATA_LOAD_DASHBOARD = 'DATA_E3_1_1_DATA_LOAD_DASHBOARD';
export const DATA_E3_1_1_DEPLOYMENT_TRACKING_DASHBOARD = 'DATA_E3_1_1_DEPLOYMENT_TRACKING_DASHBOARD';
export const DATA_E3_1_2_DEPLOYMENT_TRACKING_DASHBOARD = 'DATA_E3_1_2_DEPLOYMENT_TRACKING_DASHBOARD';
export const DATA_E3_1_1_ERROR_TRACKING_DASHBOARD = 'DATA_E3_1_1_ERROR_TRACKING_DASHBOARD';
export const DATA_E3_1_1_UNECE_ERROR_TRACKING_DASHBOARD = 'DATA_E3_1_1_UNECE_ERROR_TRACKING_DASHBOARD';

export const ORU_SMART_CAMPAIGN_EDIT = 'ORU_SMART_CAMPAIGN_EDIT';
export const ORU_SMART_CAMPAIGN_EDIT_TARGET_BASELINE = 'ORU_SMART_CAMPAIGN_EDIT_TARGET_BASELINE';
export const ORU_SMART_CAMPAIGN_EDIT_TARGET_VEHICLES = 'ORU_SMART_CAMPAIGN_EDIT_TARGET_VEHICLES';
export const ORU_SMART_CAMPAIGN_EDIT_PARAMETERS = 'ORU_SMART_CAMPAIGN_EDIT_PARAMETERS';
export const ORU_SMART_CAMPAIGN_EDIT_REQUEST_DEPLOYMENT = 'ORU_SMART_CAMPAIGN_EDIT_REQUEST_DEPLOYMENT';
export const ORU_SMART_CAMPAIGN_EDIT_ACCEPT_DEPLOYMENT = 'ORU_SMART_CAMPAIGN_EDIT_ACCEPT_DEPLOYMENT';
export const ORU_SMART_CAMPAIGN_EDIT_PLAN_DEPLOYMENT = 'ORU_SMART_CAMPAIGN_EDIT_PLAN_DEPLOYMENT';
export const ORU_SMART_CAMPAIGN_EDIT_ROLLOUT = 'ORU_SMART_CAMPAIGN_EDIT_ROLLOUT';

export const DATA_E3_2_0_ORU_DEVELOPER_DASHBOARD = 'DATA_E3_2_0_ORU_DEVELOPER_DASHBOARD';
export const DATA_ANALYTICS_ORU_23 = 'DATA_ANALYTICS_ORU_23';
export const DATA_E3_1_0_ORU_23_ERROR_TRACKING_DASHBOARD = 'DATA_E3_1_0_ORU_23_ERROR_TRACKING_DASHBOARD';
export const DATA_E3_1_1_ORU_DEVELOPER_MODE_DASHBOARD = 'DATA_E3_1_1_ORU_DEVELOPER_MODE_DASHBOARD';
export const DATA_E3_1_1_E3_1_2_AEC_ERROR_TRACKING_DASHBOARD = 'DATA_E3_1_1_E3_1_2_AEC_ERROR_TRACKING_DASHBOARD';
export const DATA_E3_1_1_E3_1_2_AEC_DEPLOYMENT_TRACKING_DASHBOARD =
  'DATA_E3_1_1_E3_1_2_AEC_DEPLOYMENT_TRACKING_DASHBOARD';

export const DEMO = 'DEMO';
export const DEMO_LAYOUT = 'DEMO_LAYOUT';
export const DEMO_BUTTON = 'DEMO_BUTTON';
export const DEMO_TAGS = 'DEMO_TAGS';
export const DEMO_LAZYSTATE = 'DEMO_LAZYSTATE';
export const DEMO_TABLE = 'DEMO_TABLE';
export const DEMO_FORM = 'DEMO_FORM';
export const DEMO_FORM_BUTTONBAR = 'DEMO_FORM_BUTTONBAR';
export const DEMO_FORM_USEFORM = 'DEMO_FORM_USEFORM';
export const DEMO_FORM_UPLOAD = 'DEMO_FORM_UPLOAD';
export const DEMO_FORM_HELPERS = 'DEMO_FORM_HELPERS';
export const DEMO_TOPBAR = 'DEMO_TOPBAR';
export const DEMO_WIDGET = 'DEMO_WIDGET';
export const DEMO_CARD = 'DEMO_CARD';
export const DEMO_STEPPER = 'DEMO_STEPPER';
export const DEMO_POPUP = 'DEMO_POPUP';
export const DEMO_NAVIGATION = 'DEMO_NAVIGATION';
export const DEMO_NAVIGATION_SUB1 = 'DEMO_NAVIGATION_SUB1';
export const DEMO_NAVIGATION_SUB2 = 'DEMO_NAVIGATION_SUB2';
export const DEMO_NAVIGATION_SUB3 = 'DEMO_NAVIGATION_SUB3';
export const DEMO_NAVIGATION_SUB4 = 'DEMO_NAVIGATION_SUB4';
export const DEMO_AFFIX = 'DEMO_AFFIX';
export const DEMO_AFFIX_SUB1 = 'DEMO_AFFIX_SUB1';
export const DEMO_AFFIX_SUB2 = 'DEMO_AFFIX_SUB2';
export const DEMO_TIMELINE = 'DEMO_TIMELINE';
export const DEMO_DEFAULT_PAGE_PARENT = 'DEMO_DEFAULT_PAGE_PARENT';
export const DEMO_DEFAULT_PAGE = 'DEMO_DEFAULT_PAGE';
export const DEMO_DEFAULT_SUB_SECOND = 'DEMO_DEFAULT_SUB_SECOND';
export const DEMO_DEFAULT_SUB_THIRD = 'DEMO_DEFAULT_SUB_THIRD';
export const DEMO_ENTITY_PAGE = 'DEMO_ENTITY_PAGE';
export const DEMO_COMMENTS = 'DEMO_COMMENTS';

export const DLCM_GATE_BLU_LIST = 'DLCM_GATE_BLU_LIST';
export const DLCM_GATE_BLU = 'DLCM_GATE_BLU';
export const DLCM_GATE_BLU_OVERVIEW = 'DLCM_GATE_BLU_OVERVIEW';
export const DLCM_GATE_BLU_VERSION = 'DLCM_GATE_BLU_VERSION';
export const DLCM_GATE_BLU_VERSION_GENERAL = 'DLCM_GATE_BLU_VERSION_GENERAL';
export const DLCM_GATE_BLU_VERSION_CURRENT = 'DLCM_GATE_BLU_VERSION_CURRENT';
export const DLCM_GATE_BLU_VERSION_TARGET = 'DLCM_GATE_BLU_VERSION_TARGET';
export const DLCM_GATE_BLU_ECU_RELEASE_LIST = 'DLCM_GATE_BLU_ECU_RELEASE_LIST';
export const DLCM_GATE_BLU_ECU_RELEASE = 'DLCM_GATE_BLU_ECU_RELEASE';
export const DLCM_GATE_BLU_ECU_RELEASE_OVERVIEW = 'DLCM_GATE_BLU_ECU_RELEASE_OVERVIEW';
export const DLCM_GATE_BLU_ECU_RELEASE_VERSION = 'DLCM_GATE_BLU_ECU_RELEASE_VERSION';
export const DLCM_GATE_BLU_ECU_RELEASE_VERSION_GENERAL = 'DLCM_GATE_BLU_ECU_RELEASE_VERSION_GENERAL';
export const DLCM_GATE_BLU_ECU_RELEASE_VERSION_CURRENT = 'DLCM_GATE_BLU_ECU_RELEASE_VERSION_CURRENT';
export const DLCM_GATE_BLU_ECU_RELEASE_VERSION_TARGET = 'DLCM_GATE_BLU_ECU_RELEASE_VERSION_TARGET';
export const DLCM_GATE_BLU_ECU_RELEASE_VERSION_APPROVAL = 'DLCM_GATE_BLU_ECU_RELEASE_VERSION_APPROVAL';
export const DLCM_GATE_BLU_ECU_RELEASE_GATE2 = 'DLCM_GATE_BLU_ECU_RELEASE_GATE2';
export const DLCM_GATE_BLU_ECU_RELEASE_GATE2_TECHNICAL = 'DLCM_GATE_BLU_ECU_RELEASE_GATE2_TECHNICAL';
export const DLCM_GATE_BLU_ECU_RELEASE_GATE2_COMPATIBILITY = 'DLCM_GATE_BLU_ECU_RELEASE_GATE2_COMPATIBILITY';
export const DLCM_GATE_BLU_ECU_RELEASE_GATE2_FUNCTIONAL = 'DLCM_GATE_BLU_ECU_RELEASE_GATE2_FUNCTIONAL';
export const DLCM_GATE_BLU_ECU_RELEASE_GATE2_TESTING = 'DLCM_GATE_BLU_ECU_RELEASE_GATE2_TESTING';
export const DLCM_GATE_BLU_ECU_RELEASE_GATE2_ORGANIZATIONAL = 'DLCM_GATE_BLU_ECU_RELEASE_GATE2_ORGANIZATIONAL';
export const DLCM_GATE_BLU_ECU_RELEASE_GATE2_APPROVAL = 'DLCM_GATE_BLU_ECU_RELEASE_GATE2_APPROVAL';
export const DLCM_GATE_BLU_ECU_RELEASE_BASELINES = 'DLCM_GATE_BLU_ECU_RELEASE_BASELINES';
export const DLCM_GATE_BLU_CUSTOMER_VEHICLE_FUNCTION_LIST = 'DLCM_GATE_BLU_CUSTOMER_VEHICLE_FUNCTION_LIST';
export const DLCM_GATE_BLU_CUSTOMER_VEHICLE_FUNCTION_VERSION = 'DLCM_GATE_BLU_CUSTOMER_VEHICLE_FUNCTION_VERSION';
export const DLCM_GATE_BLU_CUSTOMER_VEHICLE_FUNCTION_VERSION_GENERAL =
  'DLCM_GATE_BLU_CUSTOMER_VEHICLE_FUNCTION_VERSION_GENERAL';
export const DLCM_GATE_BLU_CUSTOMER_VEHICLE_FUNCTION_VERSION_DMS_REFERENCE =
  'DLCM_GATE_BLU_CUSTOMER_VEHICLE_FUNCTION_VERSION_DMS_REFERENCE';
export const DLCM_GATE_BLU_CUSTOMER_VEHICLE_FUNCTION_VERSION_SAFETY_REQUIREMENTS =
  'DLCM_GATE_BLU_CUSTOMER_VEHICLE_FUNCTION_VERSION_SAFETY_REQUIREMENTS';
export const DLCM_GATE_BLU_CUSTOMER_VEHICLE_FUNCTION_VERSION_APPROVAL =
  'DLCM_GATE_BLU_CUSTOMER_VEHICLE_FUNCTION_VERSION_APPROVAL';
export const DLCM_GATE_BLU_RISK_LIST = 'DLCM_GATE_BLU_RISK_LIST';
export const DLCM_GATE_BLU_RISK_VERSION = 'DLCM_GATE_BLU_RISK_VERSION';
export const DLCM_GATE_BLU_RISK_VERSION_GENERAL = 'DLCM_GATE_BLU_RISK_VERSION_GENERAL';
export const DLCM_GATE_BLU_RISK_VERSION_APPROVAL = 'DLCM_GATE_BLU_RISK_VERSION_APPROVAL';
export const DLCM_GATE_BLU_PRECONDITIONS = 'DLCM_GATE_BLU_PRECONDITIONS';
export const DLCM_GATE_BLU_PRECONDITIONS_TECHNICAL = 'DLCM_GATE_BLU_PRECONDITIONS_TECHNICAL';
export const DLCM_GATE_BLU_PRECONDITIONS_COMPATIBILITY = 'DLCM_GATE_BLU_PRECONDITIONS_COMPATIBILITY';
export const DLCM_GATE_BLU_PRECONDITIONS_FUNCTIONAL = 'DLCM_GATE_BLU_PRECONDITIONS_FUNCTIONAL';
export const DLCM_GATE_BLU_PRECONDITIONS_TESTING = 'DLCM_GATE_BLU_PRECONDITIONS_TESTING';
export const DLCM_GATE_BLU_PRECONDITIONS_ORGANIZATIONAL = 'DLCM_GATE_BLU_PRECONDITIONS_ORGANIZATIONAL';
export const DLCM_RELEASE_MANAGEMENT_DASHBOARD = 'DLCM_RELEASE_MANAGEMENT_DASHBOARD';
export const DRMD_INFORMATION_INFRASTRUCTURE = 'DRMD_INFORMATION_INFRASTRUCTURE';
export const DLCM_PROCESS_PRECONDITIONS = 'DLCM_PROCESS_PRECONDITIONS';
export const DRMD_FUNCTIONAL_SAFETY_TECHNICAL_RESTRICTIONS = 'DRMD_FUNCTIONAL_SAFETY_TECHNICAL_RESTRICTIONS';
export const DRMD_OTA_UPDATE_GUIDELINES = 'DRMD_OTA_UPDATE_GUIDELINES';
export const DRMD_MATURITY_CHECK = 'DRMD_MATURITY_CHECK';
export const DRMD_BASELINE_ROLLOUT_CONFIRMATION = 'DRMD_BASELINE_ROLLOUT_CONFIRMATION';
export const DRMD_LEGAL_CHECK = 'DRMD_LEGAL_CHECK';

export const AEC_SW_PROJECT_EDIT = 'AEC_SW_PROJECT_EDIT';
export const AEC_SW_PROJECT_RELEASE_OVERVIEW = 'AEC_SW_PROJECT_RELEASE_OVERVIEW';
export const AEC_SW_PROJECT_RELEASE_CONTAINER = 'AEC_SW_PROJECT_RELEASE_CONTAINER';
export const AEC_SW_PROJECT_RELEASE_VERSION_DOCUMENTATION = 'AEC_SW_PROJECT_RELEASE_VERSION_DOCUMENTATION';
export const AEC_SW_PROJECT_RELEASE_LIVE_DEPLOYMENT_CONTAINER_CREATE =
  'AEC_SW_PROJECT_RELEASE_LIVE_DEPLOYMENT_CONTAINER_CREATE';
export const AEC_SW_PROJECT_RELEASE_LIVE_DEPLOYMENT_CONTAINER_DEPLOY =
  'AEC_SW_PROJECT_RELEASE_LIVE_DEPLOYMENT_CONTAINER_DEPLOY';
export const AEC_SW_PROJECT_RELEASE_LIVE_DEPLOYMENT_OVERVIEW = 'AEC_SW_PROJECT_RELEASE_LIVE_DEPLOYMENT_OVERVIEW';
export const AEC_SW_PROJECT_RELEASE_LIVE_DEPLOYMENT_TARGET_VEHICLES =
  'AEC_SW_PROJECT_RELEASE_LIVE_DEPLOYMENT_TARGET_VEHICLES';
export const AEC_SW_PROJECT_RELEASE_LIVE_DEPLOYMENT_REQUEST = 'AEC_SW_PROJECT_RELEASE_LIVE_DEPLOYMENT_REQUEST';
export const AEC_SW_PROJECT_RELEASE_LIVE_DEPLOYMENT_PLAN = 'AEC_SW_PROJECT_RELEASE_LIVE_DEPLOYMENT_PLAN';
export const AEC_SW_PROJECT_RELEASE_LIVE_DEPLOYMENT_ROLLOUT = 'AEC_SW_PROJECT_RELEASE_LIVE_DEPLOYMENT_ROLLOUT';
export const AEC_SW_PROJECT_RELEASE_CANDIDATE_CONTAINER = 'AEC_SW_PROJECT_RELEASE_CANDIDATE_CONTAINER';
export const AEC_SW_PROJECT_RELEASE_CANDIDATE_SOFTWARE = 'AEC_SW_PROJECT_RELEASE_CANDIDATE_SOFTWARE';
export const AEC_SW_PROJECT_RELEASE_CANDIDATE_VEHICLES_SCOPE = 'AEC_SW_PROJECT_RELEASE_CANDIDATE_VEHICLES_SCOPE';
export const AEC_SW_PROJECT_RELEASE_CANDIDATE_APPROVAL = 'AEC_SW_PROJECT_RELEASE_CANDIDATE_APPROVAL';
export const DRMD_MILESTONE = 'DRMD_MILESTONE';
export const DRMD_DOCUMENTATION = 'DRMD_DOCUMENTATION';
export const DRMD_RISKS_CREATE = 'DRMD_RISKS_CREATE';
export const DRMD_RISKS_EDIT = 'DRMD_RISKS_EDIT';
export const DRMD_TESTS = 'DRMD_TESTS';
export const DRMD_DEPLOYMENTS = 'DRMD_DEPLOYMENTS';
export const DRMD_RISKS = 'DRMD_RISKS';

export const AEC_SW_PROJECT_RELEASE_CANDIDATE_APPROVAL_MODULE_MANIFEST_CHECK =
  'AEC_SW_PROJECT_RELEASE_CANDIDATE_APPROVAL_MODULE_MANIFEST_CHECK';
export const AEC_SW_PROJECT_RELEASE_CANDIDATE_APPROVAL_PUSH_TO_APPROVAL =
  'AEC_SW_PROJECT_RELEASE_CANDIDATE_APPROVAL_PUSH_TO_APPROVAL';
export const AEC_SW_PROJECT_RELEASE_CANDIDATE_APPROVAL_RELEASE_NOTES =
  'AEC_SW_PROJECT_RELEASE_CANDIDATE_APPROVAL_RELEASE_NOTES';
export const AEC_SW_PROJECT_RELEASE_CANDIDATE_APPROVAL_SUMO_APPROVAL =
  'AEC_SW_PROJECT_RELEASE_CANDIDATE_APPROVAL_SUMO_APPROVAL';
export const AEC_SW_PROJECT_RELEASE_CANDIDATE_APPROVAL_SUMO_APPROVAL_SCM_DOCUMENTATION =
  'AEC_SW_PROJECT_RELEASE_CANDIDATE_APPROVAL_SUMO_APPROVAL_SCM_DOCUMENTATION';
export const AEC_SW_PROJECT_RELEASE_CANDIDATE_APPROVAL_SUMO_APPROVAL_FUNCTIONAL_VALIDATION =
  'AEC_SW_PROJECT_RELEASE_CANDIDATE_APPROVAL_SUMO_APPROVAL_FUNCTIONAL_VALIDATION';
export const AEC_SW_PROJECT_RELEASE_CANDIDATE_APPROVAL_SUMO_APPROVAL_BRAND_APPROVAL =
  'AEC_SW_PROJECT_RELEASE_CANDIDATE_APPROVAL_SUMO_APPROVAL_BRAND_APPROVAL';
export const AEC_SW_PROJECT_RELEASE_CANDIDATE_APPROVAL_BRAND_APPROVAL_REVIEW_DOCUMENTATION =
  'AEC_SW_PROJECT_RELEASE_CANDIDATE_APPROVAL_BRAND_APPROVAL_REVIEW_DOCUMENTATION';
export const AEC_SW_PROJECT_RELEASE_CANDIDATE_APPROVAL_BRAND_APPROVAL_SUBMIT_APPROVAL =
  'AEC_SW_PROJECT_RELEASE_CANDIDATE_APPROVAL_BRAND_APPROVAL_SUBMIT_APPROVAL';
export const AEC_SW_PROJECT_RELEASE_CANDIDATE_APPROVAL_BRAND_APPROVAL_CONTAINER =
  'AEC_SW_PROJECT_RELEASE_CANDIDATE_APPROVAL_BRAND_APPROVAL_CONTAINER';
export const AEC_SW_PROJECT_RELEASE_CANDIDATE_APPROVAL_DEPLOYMENT_REQUEST =
  'AEC_SW_PROJECT_RELEASE_CANDIDATE_APPROVAL_DEPLOYMENT_REQUEST';
export const AEC_SW_PROJECT_RELEASE_CANDIDATE_APPROVAL_APPROVE_DEPLOYMENT_REQUEST =
  'AEC_SW_PROJECT_RELEASE_CANDIDATE_APPROVAL_APPROVE_DEPLOYMENT_REQUEST';
export const AEC_SW_PROJECT_RELEASE_CANDIDATE_APPROVAL_CONFIRMED_BY_SDT =
  'AEC_SW_PROJECT_RELEASE_CANDIDATE_APPROVAL_CONFIRMED_BY_SDT';
export const AEC_SW_PROJECT_RELEASE_CANDIDATE_APPROVAL_SUMO_PUSH_TOLIVE =
  'AEC_SW_PROJECT_RELEASE_CANDIDATE_APPROVAL_SUMO_PUSH_TOLIVE';
export const AEC_SW_PROJECT_RELEASE_CANDIDATE_APPROVAL_SUMO_CREATE_DEPLOYMENT =
  'AEC_SW_PROJECT_RELEASE_CANDIDATE_APPROVAL_SUMO_CREATE_DEPLOYMENT';
export const AEC_SW_PROJECT_RELEASE_CANDIDATE_DOCUMENTATION = 'AEC_SW_PROJECT_RELEASE_CANDIDATE_DOCUMENTATION';
export const AEC_SW_PROJECT_RELEASE_CANDIDATE_DOCUMENTATION_DOCUMENT =
  'AEC_SW_PROJECT_RELEASE_CANDIDATE_DOCUMENTATION_DOCUMENT';
export const AEC_SW_PROJECT_RELEASE_CANDIDATE_DOCUMENTATION_MODULE_MANIFEST =
  'AEC_SW_PROJECT_RELEASE_CANDIDATE_DOCUMENTATION_MODULE_MANIFEST';
export const AEC_SW_PROJECT_RELEASE_CANDIDATE_TESTS = 'AEC_SW_PROJECT_RELEASE_CANDIDATE_TESTS';
export const AEC_SW_PROJECT_RELEASE_CANDIDATE_DEPLOYMENTS = 'AEC_SW_PROJECT_RELEASE_CANDIDATE_DEPLOYMENTS';
export const AEC_TEST_DEPLOYMENTS_CONTAINER = 'AEC_TEST_DEPLOYMENTS_CONTAINER';
export const AEC_TEST_DEPLOYMENTS_INFO = 'AEC_TEST_DEPLOYMENTS_INFO';
export const AEC_TEST_DEPLOYMENTS_VEHICLES = 'AEC_TEST_DEPLOYMENTS_VEHICLES';
export const AEC_TEST_DEPLOYMENTS_TRACKING = 'AEC_TEST_DEPLOYMENTS_TRACKING';
export const AEC_TEST_DEPLOYMENTS_LOG = 'AEC_TEST_DEPLOYMENTS_LOG';

export const VEHICLE_MANAGEMENT = 'VEHICLE_MANAGEMENT';
export const VEHICLE_MANAGEMENT_TEST_VEHICLE_DATABASE = 'VEHICLE_MANAGEMENT_TEST_VEHICLE_DATABASE';
export const VEHICLE_MANAGEMENT_EDIT_TEST_VEHICLE = 'VEHICLE_MANAGEMENT_EDIT_TEST_VEHICLE';
export const VEHICLE_MANAGEMENT_EDIT_TEST_VEHICLE_GENERAL_INFORMATION =
  'VEHICLE_MANAGEMENT_EDIT_TEST_VEHICLE_GENERAL_INFORMATION';
export const VEHICLE_MANAGEMENT_EDIT_TEST_VEHICLE_LOCATION_DEPARTMENT =
  'VEHICLE_MANAGEMENT_EDIT_TEST_VEHICLE_LOCATION_DEPARTMENT';
export const VEHICLE_MANAGEMENT_EDIT_TEST_VEHICLE_CONSTRUCTION_STATE =
  'VEHICLE_MANAGEMENT_EDIT_TEST_VEHICLE_CONSTRUCTION_STATE';

export const E3_1_1_ORU_DEPLOYMENT_REQUEST = 'E3_1_1_ORU_DEPLOYMENT_REQUEST';
export const E3_1_1_ORU_DEPLOYMENT_REQUEST_GENERAL = 'E3_1_1_ORU_DEPLOYMENT_REQUEST_GENERAL';
export const E3_1_1_ORU_DEPLOYMENT_REQUEST_VEHICLES = 'E3_1_1_ORU_DEPLOYMENT_REQUEST_VEHICLES';
export const E3_1_1_ORU_DEPLOYMENT_REQUEST_ECU_UPDATES = 'E3_1_1_ORU_DEPLOYMENT_REQUEST_ECU_UPDATES';
export const E3_1_1_ORU_DEPLOYMENT_REQUEST_ECU_UPDATES_AND_VEHICLES =
  'E3_1_1_ORU_DEPLOYMENT_REQUEST_ECU_UPDATES_AND_VEHICLES';
export const E3_1_1_ORU_DEPLOYMENT = 'E3_1_1_ORU_DEPLOYMENT';
export const E3_1_1_ORU_DEPLOYMENT_OVERVIEW = 'E3_1_1_ORU_DEPLOYMENT_OVERVIEW';
export const E3_1_1_ORU_DEPLOYMENT_INFORMATION = 'E3_1_1_ORU_DEPLOYMENT_INFORMATION';
export const E3_1_1_ORU_DEPLOYMENT_INFORMATION_GENERAL = 'E3_1_1_ORU_DEPLOYMENT_INFORMATION_GENERAL';
export const E3_1_1_ORU_DEPLOYMENT_INFORMATION_ECU_UPDATES = 'E3_1_1_ORU_DEPLOYMENT_INFORMATION_ECU_UPDATES';
export const E3_1_1_ORU_DEPLOYMENT_VEHICLES = 'E3_1_1_ORU_DEPLOYMENT_VEHICLES';
export const E3_1_1_ORU_DEPLOYMENT_CPL = 'E3_1_1_ORU_DEPLOYMENT_CPL';
export const E3_1_1_ORU_DEPLOYMENT_PARAMETERS = 'E3_1_1_ORU_DEPLOYMENT_PARAMETERS';
export const E3_1_1_ORU_DEPLOYMENT_PARAMETERS_ENERGY = 'E3_1_1_ORU_DEPLOYMENT_PARAMETERS_ENERGY';
export const E3_1_1_ORU_DEPLOYMENT_PARAMETERS_OVERRULING = 'E3_1_1_ORU_DEPLOYMENT_PARAMETERS_OVERRULING';
export const E3_1_1_ORU_DEPLOYMENT_PARAMETERS_SPECIFIC = 'E3_1_1_ORU_DEPLOYMENT_PARAMETERS_SPECIFIC';
export const E3_1_1_ORU_DEPLOYMENT_TRACKING = 'E3_1_1_ORU_DEPLOYMENT_TRACKING';

export const TEMPLATE_DASHBOARD_VEHICLE_MANAGEMENT = 'TEMPLATE_DASHBOARD_VEHICLE_MANAGEMENT';
export const TEMPLATE_DASHBOARD_VEHICLE_MANAGEMENT_TEST_VEHICLE_DATABASE =
  'TEMPLATE_DASHBOARD_VEHICLE_MANAGEMENT_TEST_VEHICLE_DATABASE';
export const TEMPLATE_DASHBOARD_VEHICLE_MANAGEMENT_VIN_LIST_MANAGEMENT =
  'TEMPLATE_DASHBOARD_VEHICLE_MANAGEMENT_VIN_LIST_MANAGEMENT';
export const TEMPLATE_DASHBOARD_DATA_CENTER = 'TEMPLATE_DASHBOARD_DATA_CENTER';
export const TEMPLATE_DASHBOARD_DATA_CENTER_MOD34_DASHBOARD = 'TEMPLATE_DASHBOARD_DATA_CENTER_MOD34_DASHBOARD';
export const TEMPLATE_DASHBOARD_DATA_CENTER_ORU_1_1_DEPLOYMENT_TRACKING =
  'TEMPLATE_DASHBOARD_DATA_CENTER_ORU_1_1_DEPLOYMENT_TRACKING';
export const TEMPLATE_DASHBOARD_DATA_CENTER_ORU_1_1_DEPLOYMENT_TRACKING_VIN_DRILL =
  'TEMPLATE_DASHBOARD_DATA_CENTER_ORU_1_1_DEPLOYMENT_TRACKING_VIN_DRILL';
export const TEMPLATE_DASHBOARD_DATA_CENTER_ORU_1_1_ERROR_TRACKING =
  'TEMPLATE_DASHBOARD_DATA_CENTER_ORU_1_1_ERROR_TRACKING';
export const TEMPLATE_DASHBOARD_DATA_CENTER_ORU_1_1_UNECE_ERROR_TRACKING =
  'TEMPLATE_DASHBOARD_DATA_CENTER_ORU_1_1_UNECE_ERROR_TRACKING';
export const TEMPLATE_DASHBOARD_DATA_CENTER_E_3_1_1_ORU_DEVELOPER_MODE =
  'TEMPLATE_DASHBOARD_DATA_CENTER_E_3_1_1_ORU_DEVELOPER_MODE';
export const TEMPLATE_DASHBOARD_DATA_CENTER_E_3_1_1_E_3_1_2_AEC_ERROR_TRACKING =
  'TEMPLATE_DASHBOARD_DATA_CENTER_E_3_1_1_E_3_1_2_AEC_ERROR_TRACKING';
export const TEMPLATE_DASHBOARD_DATA_CENTER_E_3_1_1_E_3_1_2_AEC_DEPLOYMENT_TRACKING =
  'TEMPLATE_DASHBOARD_DATA_CENTER_E_3_1_1_E_3_1_2_AEC_DEPLOYMENT_TRACKING';
export const TEMPLATE_DASHBOARD_DATA_CENTER_DATA_LOAD_MANAGEMENT_DSH =
  'TEMPLATE_DASHBOARD_DATA_CENTER_DATA_LOAD_MANAGEMENT_DSH';
export const TEMPLATE_DASHBOARD_DATA_CENTER_MLB_MQB_ORU_DEPLOYMENT_TRACKING_ORU_1_0_23 =
  'TEMPLATE_DASHBOARD_DATA_CENTER_MLB_MQB_ORU_DEPLOYMENT_TRACKING_ORU_1_0_23';
export const TEMPLATE_DASHBOARD_DATA_CENTER_MLB_MQB_ORU_ERROR_TRACKING_ORU_1_0_23 =
  'TEMPLATE_DASHBOARD_DATA_CENTER_MLB_MQB_ORU_ERROR_TRACKING_ORU_1_0_23';
export const TEMPLATE_DASHBOARD_DATA_CENTER_ORU_1_2_DEPLOYMENT_TRACKING =
  'TEMPLATE_DASHBOARD_DATA_CENTER_ORU_1_2_DEPLOYMENT_TRACKING';
export const TEMPLATE_DASHBOARD_DATA_CENTER_E_3_2_0_ORU_DEVELOPER_MODE_ERROR_TRACKING =
  'TEMPLATE_DASHBOARD_DATA_CENTER_E_3_2_0_ORU_DEVELOPER_MODE_ERROR_TRACKING';
export const TEMPLATE_DASHBOARD_ARCHIVE = 'TEMPLATE_DASHBOARD_ARCHIVE';
export const TEMPLATE_DASHBOARD_ARCHIVE_MODULES = 'TEMPLATE_DASHBOARD_ARCHIVE_MODULES';
export const TEMPLATE_DASHBOARD_PARAMETERS = 'TEMPLATE_DASHBOARD_PARAMETERS';
export const TEMPLATE_DASHBOARD_PARAMETERS_SUPERSET = 'TEMPLATE_DASHBOARD_PARAMETERS_SUPERSET';
export const TEMPLATE_DASHBOARD_PARAMETERS_KEYCLOAK = 'TEMPLATE_DASHBOARD_PARAMETERS_KEYCLOAK';
export const TEMPLATE_DASHBOARD_PARAMETERS_STORYBOOK = 'TEMPLATE_DASHBOARD_PARAMETERS_STORYBOOK';
export const NO_ACCESS = 'NO_ACCESS';
